import React from "react";

import PageView from "@components/View/PageView";
import Wrapper from "@components/Wrapper";

import MainBanner from "./components/MainBanner";
import RecentPostList from "./components/RecentPostList";
import RecruitCard from "./components/RecruitCard";
import AboutTechCard from "./components/AboutTechCard";

const HomeModule = () => {
  return (
    <>
      <MainBanner />
      <RecentPostList />
      <Wrapper>
        <PageView>
          <AboutTechCard />
          <RecruitCard />
        </PageView>
      </Wrapper>
    </>
  );
};

export default HomeModule;
