import { AllPostDataType } from "@components/Post/type";
import { useStaticQuery, graphql } from "gatsby";

export const useAllPostData = () => {
  const { allMarkdownRemark } = useStaticQuery<AllPostDataType>(
    graphql`
      query AllBlogPostData {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          nodes {
            frontmatter {
              title
              subtitle
              date(formatString: "YYYY.MM.DD")
              category
              thumbnail {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
              tags
            }
            fields {
              slug
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark;
};
