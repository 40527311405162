// extracted by mini-css-extract-plugin
export var card = "MainCard-module--card--e280e";
export var container = "MainCard-module--container--b016f";
export var icon = "MainCard-module--icon--6bb85";
export var inner = "MainCard-module--inner--51cc4";
export var more = "MainCard-module--more--1f89c";
export var sub = "MainCard-module--sub--de2da";
export var text = "MainCard-module--text--304ff";
export var thum = "MainCard-module--thum--1315d";
export var tit = "MainCard-module--tit--7ff8d";
export var title = "MainCard-module--title--94400";