import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./MainCard.module.scss";

const AboutTechCard = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>About Oliveyoung Tech</p>
      <section className={styles.card}>
        <div className={styles.thum}>
          <Link to="https://oliveyoung.tech/blog/2022-04-27/Oliveyoung-Tech-Introduce/">
            <StaticImage
              src={
                "../../../../static/assets/background/bg_oliveyoung_tech.png"
              }
              alt={"올리브영 테크 개발자 브이로그"}
              className={styles.inner}
            />
          </Link>
        </div>
        <div>
          <p className={styles.tit}>
            Hello,
            <br />
            Oliveyoung Tech!
          </p>
          <p className={styles.sub}>
            올리브영 테크 직군에 대해 알고싶으신가요?
            <br />
            현직 개발자가 소개하는 테크 직군을 확인하세요!
          </p>
          <Link
            className={styles.more}
            to="https://oliveyoung.tech/blog/2022-04-27/Oliveyoung-Tech-Introduce/"
          >
            <p className={styles.text}>더보기</p>
            <div className={styles.icon} />
          </Link>
          <div className={styles.more}></div>
        </div>
      </section>
    </div>
  );
};

export default AboutTechCard;
