// extracted by mini-css-extract-plugin
export var container = "RecentPostList-module--container--9136a";
export var content = "RecentPostList-module--content--5ab6c";
export var date = "RecentPostList-module--date--b0ddc";
export var inner = "RecentPostList-module--inner--95a68";
export var item = "RecentPostList-module--item--23769";
export var next = "RecentPostList-module--next--0f7b1";
export var prev = "RecentPostList-module--prev--4a2ba";
export var sub = "RecentPostList-module--sub--351d6";
export var thum = "RecentPostList-module--thum--121ec";
export var tit = "RecentPostList-module--tit--3df30";
export var title = "RecentPostList-module--title--16b35";