import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./RecentPostList.module.scss";

import { useAllPostData } from "@hooks/useAllPostData";
import { PostFrontmatterType } from "@components/Post/type";

const RecentPostItem = ({
  frontmatter: { title, subtitle, date, thumbnail },
  fields: { slug },
}: PostFrontmatterType) => {
  return (
    <Link to={`${slug}`} className={styles.item}>
      <div className={styles.thum}>
        <GatsbyImage
          image={getImage(thumbnail?.childImageSharp?.gatsbyImageData)!}
          alt={`올리브영 테크블로그 최근 포스팅 ${title}`}
          className={styles.inner}
        />
      </div>
      <p className={styles.tit}>{title}</p>
      <p className={styles.sub}>{subtitle}</p>
      <p className={styles.date}>{date}</p>
    </Link>
  );
};

const RecentPostList = () => {
  const { nodes } = useAllPostData();
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [postData, setPostData] = useState<JSX.Element[]>();

  const initPostData = () => {
    if (window.outerWidth <= 600) {
      setPostData(
        nodes.slice(0, 6).map((data, index) => (
          <SwiperSlide key={index}>
            <RecentPostItem {...data} />
          </SwiperSlide>
        ))
      );
    } else {
      setPostData(
        nodes
          .slice(0, 6)
          .map((_data, index) => (
            <SwiperSlide key={index}>
              {nodes.slice(index, index + 2).map((data, index) => (
                <RecentPostItem {...data} key={index} />
              ))}
            </SwiperSlide>
          ))
          .filter((_data, index) => index % 2 === 0)
      );
    }
  };

  useEffect(() => {
    initPostData();

    window.onresize = initPostData;
  }, []);

  return (
    <article className={styles.container}>
      <p className={styles.title}>Recent Blog Posts</p>
      <section className={styles.content}>
        <Swiper
          modules={[Pagination]}
          slidesPerView={"auto"}
          centeredSlides={true}
          centeredSlidesBounds={true}
          onSwiper={setSwiper}
          onBeforeTransitionStart={(swiper) => {
            setCurrentIndex(swiper.realIndex);
          }}
          pagination={{ clickable: true }}
        >
          {postData}
        </Swiper>
        {currentIndex !== 0 && (
          <button className={styles.prev} onClick={() => swiper?.slidePrev()}>
            Prev
          </button>
        )}
        {swiper && currentIndex !== swiper?.slides.length - 1 && (
          <button className={styles.next} onClick={() => swiper?.slideNext()}>
            Next
          </button>
        )}
      </section>
    </article>
  );
};

export default RecentPostList;
