import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./MainCard.module.scss";

const RecruitCard = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Recruit</p>
      <section className={styles.card}>
        <div className={styles.thum}>
          <Link className={styles.more} to="/recruit">
            <StaticImage
              src={"../../../../static/assets/background/bg_recruit.png"}
              alt={"올리브영 테크 채용"}
              className={styles.inner}
            />
          </Link>
        </div>
        <div>
          <p className={styles.tit}>
            A global lifestyle platform
            <br />
            and a curator of healthy beauty
          </p>
          <p className={styles.sub}>
            NO.1 헬스 & 뷰티 옴니채널 브랜드, CJ올리브영에서 <br />
            건강하고 아름다운 라이프스타일을 선도하세요.
          </p>
          <Link className={styles.more} to="https://career.oliveyoung.com/">
            <p className={styles.text}>채용공고</p>
            <div className={styles.icon} />
          </Link>
          <div className={styles.more}></div>
        </div>
      </section>
    </div>
  );
};

export default RecruitCard;
