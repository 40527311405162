import React from "react";
// import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./MainBanner.module.scss";

const MainBanner = () => {
  return (
    <section className={styles.banner}>
      {/* <StaticImage
        src={"../../../../static/assets/background/bg_main_banner.jpg"}
        alt="main_banner"
        className={styles.inner}
      /> */}
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Oliveyoung Tech
        </div>
        <div className={styles.sub}>Story of Oliveyoung Engineers</div>
      </div>
    </section>
  );
};

export default MainBanner;
