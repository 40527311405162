import React from "react";

import Seo from "@components/Seo";
import Layout from "@components/Layout";

import HomeModule from "@modules/Home";

const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      <HomeModule />
    </Layout>
  );
};

export default IndexPage;
